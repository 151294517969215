import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";
import { DEMO_LOGIN } from "../assets/feedData";
import { login } from "../services/authService";

const DemoRoute = ({ children }) => {
	const { user, isDemoUser, isLoading, saveUser } = useAuthContext();

	const loginUser = async () => {
		const response = await login(DEMO_LOGIN.EMAIL, DEMO_LOGIN.PASSWORD);
		const user = await response.json();

		saveUser(user.user);
	};

	useEffect(() => {
		if (!isLoading && !isDemoUser && !user) {
			loginUser();
		}
	}, [isLoading, isDemoUser, user]);

	if (user && !isDemoUser) {
		return <Navigate to="/design-flight" replace />;
	}

	return children ? children : <Outlet />;
};

export default DemoRoute;
