import { useState } from "react";
import { Tooltip } from "react-tooltip";

import { updateVersion } from "../../services/flightService";

// Context
import { useFlightContext } from "../../contexts/FlightContext";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

// styles
import "./controls.css";
import BounceLoaderPlum from "../UI/Loader/BounceLoaderPlum";

const Regenerate = ({ id, title }) => {
	const {
		currentFlight,
		updateCurrentFlight,
		editSingleFlight,
		currentVersion,
		fetchSummary,
		fetchDesignChallenge,
		fetchMainProblems,
		fetchGoodPractices,
		fetchJobToBeDone,
		fetchPainsAndGains,
		fetchChallenges,
		fetchIdeas,
		updateSelectedChallenge,
		setSelectedIdea,
		updateCurrentVersion,
		setCurrentVersion,
	} = useFlightContext();
	const [isLoading, setIsLoading] = useState(false);

	const handleRegenerate = async () => {
		setIsLoading(true);

		let updatedVersion;

		switch (title) {
			case "Summary":
				await fetchSummary();
				break;
			case "Design Challenge":
				await fetchDesignChallenge();
				break;
			case "Main Problems":
				await fetchMainProblems();
				break;
			case "Good Practices":
				await fetchGoodPractices();
				break;
			case "Job to be Done":
				await fetchJobToBeDone();
				break;
			case "Pains & Gains":
				await fetchPainsAndGains();
				break;
			case "Challenges":
				await fetchChallenges();

				updateSelectedChallenge(null);

				updatedVersion = await updateVersion(currentVersion._id, {
					versionNumber: currentVersion.versionNumber,
					userChallenge: "",
					inspirationTexts: [],
					inspirationImages: [],
					ideas: "",
				});

				setCurrentVersion(updatedVersion);

				break;

			case "Ideas":
				await fetchIdeas();

				setSelectedIdea("");

				updatedVersion = await updateVersion(currentVersion._id, {
					versionNumber: currentVersion.versionNumber,
					selectedIdea: "",
					conceptPoster: "",
					conceptPosterImageURL: "",
				});

				setCurrentVersion(updatedVersion);

				break;
			default:
				console.log("Regenerate button clicked!");
		}

		setIsLoading(false);
	};

	return (
		<div>
			<BounceLoaderPlum isLoading={isLoading} />

			<button onClick={handleRegenerate} className="control-btn" disabled={isLoading}>
				<>
					<FontAwesomeIcon
						icon={faArrowsRotate}
						style={{ outline: "none" }}
						data-tooltip-id={`${id}-regenerate-tooltip`}
						data-tooltip-content="Regenerate"
					/>
					<Tooltip id={`${id}-regenerate-tooltip`} />
				</>
			</button>
		</div>
	);
};

export default Regenerate;
