import React, { useContext, useState, useEffect, useCallback } from "react";

import {
	getAllFlights,
	createFlight,
	deleteFlight,
	updateFlight,
	askGPT,
	generateImage,
	getVersionById,
	updateVersion,
} from "../services/flightService";
import { QUESTIONS } from "../assets/feedData";

const AppContext = React.createContext();

const FlightContextProvider = ({ children, user }) => {
	const [userFlights, setUserFlights] = useState([]);
	const [currentFlight, setCurrentFlight] = useState(null);
	const [currentVersion, setCurrentVersion] = useState(null);
	const [loadingUserFlights, setLoadingUserFlights] = useState(false);
	const [userFlightsError, setUserFlightsError] = useState(null);

	const [activeTabIndex, setActiveTabIndex] = useState(null);

	const [hasSubmittedAnswers, setHasSubmittedAnswers] = useState(false);
	const [hasSubmittedSummary, setHasSubmittedSummary] = useState(false);
	const [hasSubmittedDesignChallenge, setHasSubmittedDesignChallenge] = useState(false);
	const [hasSubmittedMainProblems, setHasSubmittedMainProblems] = useState(false);
	const [hasSubmittedGoodPractices, setHasSubmittedGoodPractices] = useState(false);
	const [hasSubmittedPointsOfView, setHasSubmittedPointsOfView] = useState(false);
	const [hasSubmittedUserJourney, setHasSubmittedUserJourney] = useState(false);
	const [hasSubmittedJobToBeDone, setHasSubmittedJobToBeDone] = useState(false);
	const [hasSubmittedPainsAndGains, setHasSubmittedPainsAndGains] = useState(false);

	const [isUserJourneyLoading, setIsUserJourneyLoading] = useState(false);

	const [selectedChallenge, setSelectedChallenge] = useState(null);
	const [selectedIdea, setSelectedIdea] = useState(null);

	const fetchFlights = async () => {
		if (!user?.userId) return;

		console.log("Fetching flights...");

		try {
			setLoadingUserFlights(true);
			setUserFlightsError(null);

			const response = await getAllFlights();

			if (!response.error) {
				setUserFlights(response.flights);
				setCurrentFlight(response.flights[0]);

				if (response.flights.length > 0) {
					const currentVersionId = response.flights[0].versions[response.flights[0].versions.length - 1];

					const currentVersion = await getVersionById(currentVersionId);

					setCurrentVersion(currentVersion);
				}
			} else {
				setUserFlightsError(response);
			}

			return response;
		} catch (error) {
			console.error("An error occurred while fetching flights: ", error);
			setUserFlightsError(error);
		} finally {
			setLoadingUserFlights(false);
		}
	};

	// Fetch flights on initial load and when user changes
	useEffect(() => {
		fetchFlights();
	}, [user]);

	// Reset triggers on initial load
	useEffect(() => {
		setTriggersToFalse();
	}, []);

	// Fetch Summary and Design Challenge
	useEffect(() => {
		if (currentVersion) {
			if (
				hasSubmittedAnswers &&
				!hasSubmittedSummary &&
				!currentVersion.summary &&
				!hasSubmittedDesignChallenge &&
				!currentVersion.designChallenge
			) {
				fetchSummary();
				setHasSubmittedSummary(true);

				fetchDesignChallenge();
				setHasSubmittedDesignChallenge(true);
			}
		}
	}, [currentVersion, hasSubmittedAnswers, hasSubmittedSummary, hasSubmittedDesignChallenge]);

	// Fetch Main Problems
	useEffect(() => {
		if (currentVersion) {
			if (
				hasSubmittedSummary &&
				currentVersion.summary &&
				hasSubmittedDesignChallenge &&
				currentVersion.designChallenge &&
				!hasSubmittedMainProblems &&
				!currentVersion.mainProblems
			) {
				fetchMainProblems();
				setHasSubmittedMainProblems(true);
			}
		}
	}, [currentVersion, hasSubmittedSummary, hasSubmittedDesignChallenge, hasSubmittedMainProblems]);

	// Fetch Good Practices
	useEffect(() => {
		if (currentVersion) {
			if (hasSubmittedMainProblems && currentVersion.mainProblems && !hasSubmittedGoodPractices && !currentVersion.goodPractices) {
				fetchGoodPractices();
				setHasSubmittedGoodPractices(true);
			}
		}
	}, [currentVersion, hasSubmittedMainProblems, hasSubmittedGoodPractices]);

	// Fetch Personas
	useEffect(() => {
		if (currentVersion) {
			if (hasSubmittedMainProblems && currentVersion.mainProblems && !hasSubmittedPointsOfView && !currentFlight.personas) {
				fetchPersonas();
				setHasSubmittedPointsOfView(true);
			}
		}
	}, [currentVersion, hasSubmittedMainProblems, hasSubmittedPointsOfView]);

	// Fetch Job to be Done
	useEffect(() => {
		if (currentVersion) {
			if (currentVersion.selectedTouchpoint && !hasSubmittedJobToBeDone && !currentVersion.jobToBeDone) {
				fetchJobToBeDone();
				setHasSubmittedJobToBeDone(true);
			}
		}
	}, [currentVersion, hasSubmittedJobToBeDone]);

	// Fetch Pains and Gains
	useEffect(() => {
		if (currentVersion) {
			if (currentVersion.selectedTouchpoint && currentVersion.jobToBeDone && !hasSubmittedPainsAndGains && !currentVersion.painsAndGains) {
				fetchPainsAndGains();
				setHasSubmittedPainsAndGains(true);
			}
		}
	}, [currentVersion, hasSubmittedPainsAndGains]);

	// Fetch Challenges
	useEffect(() => {
		if (currentVersion) {
			if (currentVersion.painsAndGains && !currentVersion.userChallengesJSON) {
				fetchChallenges();
			}
		}
	}, [currentVersion]);

	// Fetch Ideas
	useEffect(() => {
		if (currentVersion) {
			if (currentVersion.inspirationTexts && currentVersion.inspirationImages && !currentVersion.ideas) {
				fetchIdeas();
			}
		}
	}, [currentVersion]);

	// Set selected challenge when current flight changes
	useEffect(() => {
		if (currentVersion) {
			setSelectedChallenge(currentVersion.userChallenge);
		}
	}, [currentVersion]);

	// Set selected idea when current flight changes
	useEffect(() => {
		if (currentVersion) {
			setSelectedIdea(currentVersion.selectedIdea);
		}
	}, [currentVersion]);

	// Reset active tab index on initial load
	useEffect(() => {
		setActiveTabIndex(0);
	}, []);

	// Add Flight
	const addNewFlight = async () => {
		const response = await createFlight();

		if (!response.error) {
			setUserFlights((prevFlights) => [response, ...(prevFlights || [])]);
			updateCurrentFlight(response);
			updateCurrentVersion(response.versions[response.versions.length - 1] || 1);

			setActiveTabIndex(0);
			setTriggersToFalse();
		}

		return response;
	};

	// Delete Flight
	const deleteSingleFlight = async (flightId) => {
		const response = await deleteFlight(flightId);

		if (!response.error) {
			setUserFlights((prevFlights) => {
				const updatedFlights = prevFlights.filter((flight) => flight._id !== response._id);
				const updatedCurrentFlight = updatedFlights.length > 0 ? updatedFlights[0] : null;

				updateCurrentFlight(updatedCurrentFlight);

				if (updatedCurrentFlight) {
					updateCurrentVersion(updatedCurrentFlight.versions[updatedCurrentFlight.versions.length - 1]);
				}

				return updatedFlights;
			});
		}

		return response;
	};

	// Edit Flight
	const editSingleFlight = async (flightId, field, isEdit = false) => {
		const response = await updateFlight(flightId, field);

		if (!response.error) {
			setUserFlights((prevFlights) => {
				const updatedFlights = prevFlights.map((flight) => (flight._id === response._id ? response : flight));
				return updatedFlights;
			});
			updateCurrentFlight(response);

			if (isEdit) {
				updateCurrentVersion(response.versions[response.versions.length - 1]);
			} else {
				updateCurrentVersion(response.versions[currentVersion.versionNumber - 1]);
			}
		}

		return response;
	};

	const updateCurrentFlight = useCallback((flight) => {
		setCurrentFlight(flight);
	}, []);

	const updateCurrentVersion = useCallback(async (flightVersion) => {
		const currentVersion = await getVersionById(flightVersion);

		setCurrentVersion(currentVersion);
	}, []);

	const updateSelectedChallenge = useCallback((challenge) => {
		setSelectedChallenge(challenge);
	}, []);

	const setTriggersToFalse = () => {
		setHasSubmittedAnswers(false);
		setHasSubmittedSummary(false);
		setHasSubmittedDesignChallenge(false);
		setHasSubmittedMainProblems(false);
		setHasSubmittedGoodPractices(false);
		setHasSubmittedPointsOfView(false);
		setHasSubmittedUserJourney(false);
		setHasSubmittedPainsAndGains(false);
	};

	const fetchFromGPT = async (history, prompt, updateField, parseJson = false) => {
		try {
			// Fetch the response from the GPT API
			const response = await askGPT(history, prompt);
			if (response.error) {
				console.error(`${response.message}`);
				return;
			}

			// Clean and format the response
			let cleanedResponse = response.trim();
			cleanedResponse = cleanedResponse.replace(/```json|```/g, "");

			// Parse the response if required
			let parsedResponse;
			if (parseJson) {
				try {
					parsedResponse = JSON.parse(cleanedResponse);
				} catch (e) {
					console.error("Failed to parse JSON response:", e);
					parsedResponse = {}; // Fallback to an empty object
				}
			} else {
				parsedResponse = cleanedResponse;
			}

			// Update flight data
			// const updatedFlight = await editSingleFlight(currentFlight._id, {
			// 	versionNumber: currentVersion.versionNumber,
			// 	[updateField]: parsedResponse,
			// });

			// updateCurrentFlight(updatedFlight);

			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				[updateField]: parsedResponse,
			});

			setCurrentVersion(updatedVersion);
		} catch (error) {
			console.error("A problem has occured", error);
		}
	};

	const fetchSummary = async () => {
		console.log("Called fetchSummary");

		const history = [
			{
				role: "user",
				content: `Question 1: ${QUESTIONS.QUESTION_1} Answer 1: ${currentVersion.answers.answer1}
						  Question 2: ${QUESTIONS.QUESTION_2} Answer 2: ${currentVersion.answers.answer2}
						  Question 3: ${QUESTIONS.QUESTION_3} Answer 3: ${currentVersion.answers.answer3}
						  Question 4: ${QUESTIONS.QUESTION_4} Answer 4: ${currentVersion.answers.answer4}
						  Question 5: ${QUESTIONS.QUESTION_5} Answer 5: ${currentVersion.answers.answer5}`,
			},
		];

		const prompt = `As a Design Thinking Expert, summarize the 5 questions and answers in up to three sentences.`;

		await fetchFromGPT(history, prompt, "summary");
	};

	const fetchDesignChallenge = async () => {
		console.log("Called fetchDesignChallenge");

		const history = [
			{
				role: "user",
				content: `Question 1: ${QUESTIONS.QUESTION_1} Answer 1: ${currentVersion.answers.answer1}
						  Question 2: ${QUESTIONS.QUESTION_2} Answer 2: ${currentVersion.answers.answer2}
						  Question 3: ${QUESTIONS.QUESTION_3} Answer 3: ${currentVersion.answers.answer3}
						  Question 4: ${QUESTIONS.QUESTION_4} Answer 4: ${currentVersion.answers.answer4}
						  Question 5: ${QUESTIONS.QUESTION_5} Answer 5: ${currentVersion.answers.answer5}`,
			},
		];

		const prompt = `As a Design Thinking Expert, create a single 'How might we' question based on the provided 5 questions and answers.`;

		await fetchFromGPT(history, prompt, "designChallenge");
	};

	const fetchMainProblems = async () => {
		console.log("Called fetchMainProblems");

		const history = [
			{
				role: "user",
				content: `Summary: ${currentVersion.summary} Design Challenge: ${currentVersion.designChallenge}`,
			},
		];

		const prompt = `As a Design Thinking Expert, list up to 5 main problems related to the Summary and Design Challenge.`;

		await fetchFromGPT(history, prompt, "mainProblems");
	};

	const fetchGoodPractices = async () => {
		console.log("Called fetchGoodPractices");

		const history = [
			{
				role: "user",
				content: `Main Problems: ${currentVersion.mainProblems}`,
			},
		];

		const prompt = `List up to 5 concrete Good Practices. Provide specific examples that are researchable and verifiable.`;

		await fetchFromGPT(history, prompt, "goodPractices");
	};

	const fetchPersonas = async () => {
		console.log("Called fetchPersonas");

		const history = [
			{
				role: "user",
				content: `Main Problems: ${currentVersion.mainProblems}`,
			},
		];

		const prompt = `
		Transform the insights from the Main Problems into 3 Points of View (Persona + Need + Insight). 
		Identify who is primarily affected by the topic. 
	
		Please generate 2 extreme Points of View and 1 normal Point of View. Each Point of View should include the following:
	
		- **Persona**: Describe the persona's characteristics.
		- **Need**: Describe the specific need of the persona.
		- **Insight**: Provide an insight related to the persona and their need.
	
		Format your response strictly as a JSON object. Do not include any additional text, formatting, or markdown. Only include the JSON object, formatted exactly like this:
	
		{
			"Person 1": {
				"name": "[Provide name]",
				"need": "[Describe the need]",
				"insight": "[Describe the insight]"
			},
			"Person 2": {
				"name": "[Provide name]",
				"need": "[Describe the need]",
				"insight": "[Describe the insight]"
			},
			"Person 3": {
				"name": "[Provide name]",
				"need": "[Describe the need]",
				"insight": "[Describe the insight]"
			}
		}
	`;

		await fetchFromGPT(history, prompt, "personas", true);
	};

	const fetchUserJourney = async (persona) => {
		setIsUserJourneyLoading(true);

		const hasPersona = currentVersion.personas[persona].hasOwnProperty("touchpoints");

		if (hasPersona) {
			setIsUserJourneyLoading(false);
			return;
		}

		const history = [
			{
				role: "user",
				content: `Summary: ${currentVersion.summary}`,
			},
		];

		const prompt = `
		Generate a User Journey with 10 touchpoints for the following persona: 
		- Persona: ${currentVersion.personas[persona]}
		
		Format your response strictly as a JSON object. Do not include any additional text, formatting, or markdown. Only include the JSON object, formatted exactly like this:
		
		{
			"touchpoint1": {
				"title": "[Describe the point]",
				"actions": "[Describe the actions]",
				"thoughts": "[Describe the thoughts]",
				"satisfactionLevel": [Scale from -5 to 5 as number]
			},
			"touchpoint2": {
				"title": "[Describe the point]",
				"actions": "[Describe the actions]",
				"thoughts": "[Describe the thoughts]",
				"satisfactionLevel": [Scale from -5 to 5 as number]
			},
			...
			"touchpoint10": {
				"title": "[Describe the point]",
				"actions": "[Describe the actions]",
				"thoughts": "[Describe the thoughts]",
				"satisfactionLevel": [Scale from -5 to 5 as number]
			}
		}`;

		const response = await askGPT(history, prompt);

		if (!response.error) {
			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				personas: {
					...currentVersion.personas,
					[persona]: {
						...currentVersion.personas[persona],
						touchpoints: { ...JSON.parse(response) },
					},
				},
			});

			setCurrentVersion(updatedVersion);

			setHasSubmittedUserJourney(true);

			setIsUserJourneyLoading(false);
		} else {
			console.log(response.message);
		}
	};

	const fetchJobToBeDone = async () => {
		console.log("Fetching Job to be Done");

		const { touchpoints, ...persona } = currentVersion.personas[currentVersion.selectedPersona];

		const history = [];

		const prompt = `Generate a "Job to be Done" statement for the selected persona and selected touchpoint in one sentence using the following information: 
		- Persona Name: ${persona.name}
		- Touchpoint: ${Object.values(currentVersion.selectedTouchpoint)[1].title}
		- Persona Actions: ${Object.values(currentVersion.selectedTouchpoint)[1].actions}
		- Persona Thoughts: ${Object.values(currentVersion.selectedTouchpoint)[1].thoughts}
		- Persona Satisfaction Level: ${Object.values(currentVersion.selectedTouchpoint)[1].satisfactionLevel}`;

		await fetchFromGPT(history, prompt, "jobToBeDone");
	};

	const fetchPainsAndGains = async () => {
		console.log("Fetching Pains and Gains");

		// const { touchpoints, ...persona } = currentVersion.personas[currentVersion.selectedPersona];

		const history = [];

		const prompt = `Generate a "Pains and Gains" list using the following "Job to be Done" statement:
		- Job to be Done: ${currentVersion.jobToBeDone}`;

		await fetchFromGPT(history, prompt, "painsAndGains");
	};

	const fetchChallenges = async () => {
		console.log("Fetching Challenges");

		const history = [
			{
				role: "user",
				content: `Pains and Gaind: ${currentVersion.painsAndGains}`,
			},
		];

		const prompt = `Generate a design challenge for each of the listed Pains (only Pains), using the "How might we...?" principle and consisting only of short questions.

		Format the response as a JSON object using the following template:
		{
			"[Pain]": "[Design Challenge]",
			...
		}`;

		await fetchFromGPT(history, prompt, "userChallengesJSON", true);
	};

	const fetchIdeas = async () => {
		console.log("Fetching Ideas");

		const history = [
			{
				role: "user",
				content: `Persona Need: ${currentVersion.personas[currentVersion.selectedPersona].need}, Persona Insight: ${
					currentVersion.personas[currentVersion.selectedPersona].insight
				}`,
			},
		];

		const prompt = `Generate 20 solution ideas based on the inspirations in ${Object.values(
			currentVersion.inspirationTexts
		)}, applied to the question ${currentVersion.userChallengeAbstract}. \
		Focus on desirability from the perspective of ${currentVersion.personas[currentVersion.selectedPersona].name}—technical feasibility is secondary. \
		Return a list of 20 ideas as headlines only, with no additional text.`;

		await fetchFromGPT(history, prompt, "ideas");
	};

	const fetchText = async (scenario, challenge) => {
		const textDescription = await askGPT(
			[],
			`Reformulate the following question "${challenge}" by generalizing subject, objects, and activities while keeping all adjectives`
		);

		if (!textDescription) {
			console.log("Failed to fetch text description.");
			return;
		}

		try {
			const prompt = `Give an example from ${scenario} where the following question has been solved: ${textDescription}`;

			const response = await await askGPT([], prompt);

			if (!response.error) {
				return { [scenario]: response };
			} else {
				console.log(response.message);
			}
		} catch (error) {
			console.error("Error fetching text:", error);
		}
	};

	const fetchImage = async (scenario, challenge) => {
		try {
			const prompt = `Create a picture that illustrates the scenario: ${scenario}.`;

			const response = await generateImage(prompt);

			if (!response.error) {
				return { [scenario]: response };
			} else {
				console.log(response.message);
			}
		} catch (error) {
			console.error("Error fetching image:", error);
		}
	};

	return (
		<AppContext.Provider
			value={{
				// Flights
				fetchFlights,
				setUserFlights,
				userFlights,
				loadingUserFlights,
				userFlightsError,
				currentVersion,
				setCurrentVersion,
				updateCurrentVersion,
				currentFlight,
				updateCurrentFlight,
				addNewFlight,
				editSingleFlight,
				deleteSingleFlight,

				// Tabs
				activeTabIndex,
				setActiveTabIndex,

				fetchUserJourney,
				isUserJourneyLoading,

				selectedChallenge,
				selectedIdea,
				setSelectedIdea,
				updateSelectedChallenge,

				// Triggers
				hasSubmittedAnswers,
				hasSubmittedSummary,
				hasSubmittedDesignChallenge,
				hasSubmittedMainProblems,
				hasSubmittedGoodPractices,
				hasSubmittedPointsOfView,
				hasSubmittedUserJourney,
				hasSubmittedPainsAndGains,
				setHasSubmittedAnswers,
				setHasSubmittedSummary,
				setHasSubmittedDesignChallenge,
				setHasSubmittedMainProblems,
				setHasSubmittedGoodPractices,
				setHasSubmittedPointsOfView,
				setHasSubmittedUserJourney,
				setHasSubmittedJobToBeDone,
				setHasSubmittedPainsAndGains,
				setTriggersToFalse,

				// Fetch functions
				fetchSummary,
				fetchDesignChallenge,
				fetchMainProblems,
				fetchGoodPractices,
				fetchJobToBeDone,
				fetchPainsAndGains,
				fetchChallenges,
				fetchText,
				fetchImage,
				fetchFromGPT,

				fetchIdeas,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useFlightContext = () => {
	return useContext(AppContext);
};

export { FlightContextProvider };
