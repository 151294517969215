import { useFlightContext } from "../../contexts/FlightContext";
import { TabSection } from "..";

import "./tabs.css";

export default function MainProblems() {
	const { currentVersion } = useFlightContext();

	return (
		<div className="tab-container">
			<TabSection title="Main Problems" content={currentVersion.mainProblems} />
		</div>
	);
}
