import { useEffect, useState } from "react";

import { updateVersion } from "../../services/flightService";
import { useFlightContext } from "../../contexts/FlightContext";
import { TextToSpeech, SmallLoader, SolidLine, Regenerate } from "..";
import { randomId } from "../../utils/helpers";

// import "./ideation.css";
import "./tabs.css";

export default function Ideation() {
	const id = randomId();

	const { currentVersion, setCurrentVersion, setSelectedIdea, selectedIdea } = useFlightContext();

	const [ideas, setIdeas] = useState(currentVersion.ideas ? currentVersion.ideas.split("\n") : []);

	const handleClick = async (idea) => {
		setSelectedIdea(idea);

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			selectedIdea: idea,
			conceptPoster: "",
			conceptPosterImageURL: "",
		});

		setCurrentVersion(updatedVersion);
	};

	useEffect(() => {
		setSelectedIdea(currentVersion.selectedIdea);
		setIdeas(currentVersion.ideas ? currentVersion.ideas.split("\n") : []);
	}, [currentVersion]);

	return (
		<div className="tab-container">
			<h2 className="tab-title">Ideation</h2>

			{currentVersion.ideas ? (
				<>
					{ideas &&
						ideas.map((idea, index) => (
							<div key={index} className="selectable-titles-container">
								<div
									className={`selectable-title ${selectedIdea === idea ? "selected-selectable-title" : ""}`}
									onClick={() => handleClick(idea)}>
									<p>{idea}</p>
								</div>

								<TextToSpeech id={idea} text={idea} />
							</div>
						))}

					<SolidLine />
					<Regenerate id={id} title={"Ideas"} />
				</>
			) : (
				<SmallLoader />
			)}
		</div>
	);
}
