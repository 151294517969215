import { useFlightContext } from "../../contexts/FlightContext";
import { Regenerate, SmallLoader, SolidLine, TextToSpeech } from "..";

import { askGPT, updateVersion } from "../../services/flightService";
import { randomId } from "../../utils/helpers";

import "./tabs.css";

const ChallengeItem = ({ challenge, isSelected, onClick }) => (
	<div className="selectable-titles-container">
		<div className={`selectable-title ${isSelected ? "selected-selectable-title" : ""}`} onClick={onClick}>
			<p>{challenge}</p>
		</div>

		<TextToSpeech id={challenge} text={challenge} />
	</div>
);

export default function Challenges() {
	const id = randomId();
	const { currentVersion, selectedChallenge, updateSelectedChallenge, setCurrentVersion } = useFlightContext();

	const handleClick = async function (challenge) {
		updateSelectedChallenge(challenge);

		const userChallengeAbstract = await askGPT(
			[],
			`Reformulate the following question "${challenge}" by generalizing subject, objects, and activities while keeping all adjectives.`
		);

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			userChallenge: challenge,
			userChallengeAbstract: userChallengeAbstract,
			inspirationTexts: {},
			inspirationImages: {},
			ideas: "",
			selectedIdea: "",
			conceptPoster: "",
		});

		setCurrentVersion(updatedVersion);
	};

	return (
		<div className="tab-container">
			<h2 className="tab-title">Challenges</h2>

			{currentVersion.userChallengesJSON ? (
				<>
					{Object.keys(currentVersion.userChallengesJSON).map((challengeKey, index) => {
						const challenge = currentVersion.userChallengesJSON[challengeKey];
						return (
							<ChallengeItem
								key={index}
								challenge={challenge}
								isSelected={selectedChallenge === challenge}
								onClick={() => handleClick(challenge)}
							/>
						);
					})}
					<SolidLine />
					<Regenerate id={id} title={"Challenges"} />
				</>
			) : (
				<SmallLoader />
			)}
		</div>
	);
}
