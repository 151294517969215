import { useFlightContext } from "../../contexts/FlightContext";
import { TabSection } from "..";

import "./tabs.css";

export default function GoodPractices() {
	const { currentVersion } = useFlightContext();

	return (
		<div className="tab-container">
			<TabSection title="Good Practices" content={currentVersion.goodPractices} />
		</div>
	);
}
