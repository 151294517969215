import { useState, useEffect } from "react";

import { askGPT, generateImage, updateVersion } from "../../../services/flightService";
import DesignChallengeAbstract from "./DesignChallengeAbstract";
import BounceLoaderPlum from "../../UI/Loader/BounceLoaderPlum";
import Card from "./Card";
import { useFlightContext } from "../../../contexts/FlightContext";
import { SCENARIO_TITLES } from "../../../assets/feedData";
import "./inspirations.css";

export default function Inspirations() {
	const { currentVersion, setCurrentVersion } = useFlightContext();

	const [scenarios, setScenarios] = useState(currentVersion?.inspirationTexts);
	const [isScenariosLoading, setIsScenariosLoading] = useState(false);

	const [imageUrls, setImageUrls] = useState(currentVersion?.inspirationImages);
	const [isImagesLoading, setIsImagesLoading] = useState(false);

	const [showInspirations, setShowInspirations] = useState(false);

	const [error, setError] = useState(false);

	useEffect(() => {
		if (currentVersion.inspirationTexts && currentVersion.inspirationImages) {
			setShowInspirations(true);
		} else {
			setShowInspirations(false);
		}
	}, [currentVersion]);

	const handleGenerateInspirations = async () => {
		console.log("Generating Inspirations");

		setIsScenariosLoading(true);
		setIsImagesLoading(true);

		const fix = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			inspirationTexts: {},
			inspirationImages: {},
			ideas: "",
			selectedIdea: "",
			conceptPoster: "",
		});

		setCurrentVersion(fix);

		const scenarioPrompts = Object.values(SCENARIO_TITLES).map(
			(title) => `Give an example from "${title}" where the following question has been solved`
		);

		// TEXT
		const scenarioResponses = await Promise.all(
			scenarioPrompts.map(async (scenarioPrompt) => {
				let response = null;

				try {
					response = await askGPT([], `${scenarioPrompt}: ${currentVersion.userChallengeAbstract}. Keep answer under 800 characters.`);
				} catch (e) {
					console.error(e);
					setError(true);
				}

				return response;
			})
		);

		const scenarioTexts = scenarioResponses.reduce((texts, response, index) => {
			const key = Object.keys(SCENARIO_TITLES)[index];
			texts[key] = response || "";
			return texts;
		}, {});

		setShowInspirations(true);
		setScenarios(scenarioTexts);
		setIsScenariosLoading(false);

		// IMAGE
		const imageResponses = await Promise.all(
			Object.entries(scenarioTexts).map(async ([_, scenario]) => {
				const truncatedScenario = scenario.substring(0, 900);

				let response = null;

				try {
					response = await generateImage(truncatedScenario);
				} catch (e) {
					console.error(e);
					setError(true);
				}
				return response;
			})
		);

		const scenarioImgUrls = imageResponses.reduce((urls, response, index) => {
			const key = Object.keys(SCENARIO_TITLES)[index];
			urls[key] = response || "";
			return urls;
		}, {});

		setImageUrls(scenarioImgUrls);
		setIsImagesLoading(false);

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			inspirationTexts: scenarioTexts,
			inspirationImages: scenarioImgUrls,
		});

		setCurrentVersion(updatedVersion);
	};

	if (error) {
		return <div>error</div>;
	}

	return (
		<div id="inspirations" className="tab-container">
			<DesignChallengeAbstract generate={handleGenerateInspirations} />

			<BounceLoaderPlum isLoading={isScenariosLoading} />

			{showInspirations && (
				<div className="card-list">
					{Object.entries(SCENARIO_TITLES).map(([key, title]) => {
						return (
							<Card
								key={key}
								title={title}
								img_url={imageUrls?.[key]}
								text={scenarios?.[key]}
								isScenariosLoading={isScenariosLoading}
								isImagesLoading={isImagesLoading}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
}
