import { useContext, useState, useRef, useEffect } from "react";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faShare, faTrashCan, faPen, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

// Context
import { useFlightContext } from "../../contexts/FlightContext";
import DropdownContext from "../../contexts/DropdownContext";

const FlightItem = ({ flight, handleOpenModal, handleDelete }) => {
	const { currentFlight, updateCurrentFlight, setActiveTabIndex, setTriggersToFalse, editSingleFlight, updateCurrentVersion } = useFlightContext();

	const { activeDropdown, setActiveDropdown } = useContext(DropdownContext);

	const [isEditing, setIsEditing] = useState(false);
	const [title, setTitle] = useState(flight?.title);
	const inputRef = useRef();

	const handleEdit = async () => {
		setIsEditing(false);

		await editSingleFlight(flight._id, { title });
	};

	useEffect(() => {
		if (isEditing) {
			inputRef.current.focus();
		}
	}, [isEditing]);

	return (
		<div
			key={flight._id}
			className="flight-link"
			onClick={() => {
				setActiveTabIndex(0);
				updateCurrentFlight(flight);
				updateCurrentVersion(flight.versions[flight.versions.length - 1]);
				setTriggersToFalse();
			}}>
			<div className={`flight-item ${flight._id === currentFlight?._id ? "flight-item--active" : ""}`}>
				<div style={{ display: "flex" }}>
					<FontAwesomeIcon icon={faPaperPlane} className="flight-item__plane-icon" />

					{isEditing ? (
						<input
							ref={inputRef}
							style={{
								fontSize: "0.7rem",
								marginLeft: "0.5rem",
								border: "none",
								outline: "none",
								minWidth: "150px",
								maxWidth: "200px",
							}}
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							onBlur={handleEdit}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handleEdit();
								}
							}}
						/>
					) : (
						<span className="flight-item__date">
							{flight.title
								? activeDropdown === flight._id
									? `${flight.title.substring(0, 20)}...`
									: flight.title
								: new Date(flight?.createdAt).toLocaleString()}
						</span>
					)}
				</div>

				<div
					onMouseEnter={() => setActiveDropdown(activeDropdown === flight._id ? null : flight._id)}
					onMouseLeave={() => setActiveDropdown(null)}>
					{activeDropdown !== flight._id ? (
						<div style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
							<span>
								<FontAwesomeIcon icon={faEllipsisVertical} />
							</span>
						</div>
					) : (
						<div>
							<span
								data-tooltip-id="rename-tooltip"
								data-tooltip-content="Rename"
								className="flight-item__rename"
								onClick={(e) => {
									e.stopPropagation();
									setIsEditing(!isEditing);
								}}>
								<FontAwesomeIcon icon={faPen} />
								<Tooltip id="rename-tooltip" />
							</span>

							<span
								data-tooltip-id="share-tooltip"
								data-tooltip-content="Share"
								className="flight-item__share"
								onClick={(e) => {
									e.stopPropagation();
									handleOpenModal({
										id: flight._id,
									});
								}}>
								<FontAwesomeIcon icon={faShare} />
								<Tooltip id="share-tooltip" />
							</span>

							<span
								data-tooltip-id="delete-tooltip"
								data-tooltip-content="Delete"
								className="flight-item__delete"
								onClick={(e) => {
									e.stopPropagation();
									handleDelete(flight._id);
								}}>
								<FontAwesomeIcon icon={faTrashCan} />
								<Tooltip id="delete-tooltip" />
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FlightItem;
