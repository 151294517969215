import { useState } from "react";

// External Libraries
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";

// Components
import FlightItem from "./FlightItem";

// Context
import { useFlightContext } from "../../contexts/FlightContext";
import DropdownContext from "../../contexts/DropdownContext";
import { useAppContext } from "../../contexts/AppContext";

function getMarker(date) {
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	const flightDate = new Date(date);
	flightDate.setHours(0, 0, 0, 0);

	const diffTime = Math.abs(currentDate - flightDate);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays === 0) {
		return "Today";
	} else if (diffDays <= 7) {
		return "Past Week";
	} else if (diffDays <= 30) {
		return "Past Month";
	} else {
		return "Older";
	}
}

const FlightList = ({ handleAddNew, handleDelete, handleOpenModal }) => {
	const { userFlights } = useFlightContext();
	const { isSidebarVisible } = useAppContext();

	const [activeDropdown, setActiveDropdown] = useState(null);

	// Group flights by marker
	const groupedFlights = userFlights.reduce((groups, flight) => {
		const marker = getMarker(flight.createdAt);
		if (!groups[marker]) {
			groups[marker] = [];
		}
		groups[marker].push(flight);
		return groups;
	}, {});

	return (
		<div className={isSidebarVisible ? "flight-list" : "flight-list-closed"}>
			<div className="flight-add user-card-action" onClick={handleAddNew}>
				<FontAwesomeIcon icon={faPlus} />
				<span className="flight-add__text">Add New Design Flight</span>
			</div>

			<DropdownContext.Provider value={{ activeDropdown, setActiveDropdown }}>
				<InfiniteScroll dataLength={Object.keys(groupedFlights).length}>
					{Object.entries(groupedFlights).map(([marker, flights]) => (
						<div key={marker}>
							<span style={{ fontSize: "0.8rem", fontWeight: "bold", color: "#8f3a59" }}>{marker}</span>
							{flights.map((flight) => (
								<FlightItem key={flight._id} flight={flight} handleOpenModal={handleOpenModal} handleDelete={handleDelete} />
							))}
						</div>
					))}
				</InfiniteScroll>
			</DropdownContext.Provider>
		</div>
	);
};

export default FlightList;
