import { useFlightContext } from "../../contexts/FlightContext";
import { TabSection } from "..";

import "./tabs.css";

export default function DesignChallenge() {
	const { currentVersion } = useFlightContext();

	return (
		<div className="tab-container">
			<TabSection title="Summary" content={currentVersion.summary} />

			<TabSection title="Design Challenge" content={currentVersion.designChallenge} />
		</div>
	);
}
